
















































































































































































































































































































































































.el-textarea__inner {
  min-height: 20rem !important;
  resize: none;
}
